import React from 'react';
import { PageProps } from 'gatsby';
import DocDataProvider from '../../components/DocDataProvider';
import Seo from '../../components/Seo';
import { Article, PageTitle } from '../../components/Typography';
import NewSupport from '../../components/NewSupport';
import Card from '../../components/Card';
import { Constrain } from '../../components/Containers';
import YoutubeEmbed from '../../components/YoutubeEmbed';
import DocsSidebar from '../../components/DocsSidebar';
import Num from '../../components/Typography/Num';
import Button from '../../components/Button';

type VideoTypes = { videoId: string; title: string; description: string };
const videos: VideoTypes[] = [
	{
		videoId: 'HsCKUSvxcj4',
		title: 'How to create a form with WPEForm',
		description:
			'Learn to use the drag and drop form builder to create a survey form. All under 8 mins.',
	},
	{
		videoId: 'aO_RTULRO1U',
		title: 'Managing Pages in WPEForm Form Builder',
		description:
			'All forms are inherently multi-paged in WPEForm. Learn how to manage form pages.',
	},
	{
		videoId: 'UkHH2MmMnl4',
		title: 'Managing Form Elements in the Form Builder',
		description:
			'Learn how to work with form elements inside the Form Builder of WPEForm. Easily add new form elements and rearrange them.',
	},
];

export default function Docs(props: PageProps) {
	return (
		<DocDataProvider>
			<Seo
				title="WPEForm Video Tutorials | WordPress Form Builder"
				description="Explore video tutorials for WPEForm"
			/>
			<Constrain>
				<DocsSidebar.Grid>
					<DocsSidebar />
					<DocsSidebar.Content>
						<PageTitle>
							WPEForm WordPress Form Builder
							<br />
							Video Tutorials
						</PageTitle>
						<Article className="has-gutter">
							<p>
								Explore video tutorials of WPEForm. Learn to use the form
								builder, different tools and interfaces.
							</p>
						</Article>
						<Card.Grid>
							{videos.map((video, index) => (
								<Card
									key={video.videoId}
									image={<YoutubeEmbed videoId={video.videoId} />}
									layout="max-2"
									hasActiveState={false}
								>
									<Card.Title>
										<Num num={index + 1}>{video.title}</Num>
									</Card.Title>
									<Card.Description>{video.description}</Card.Description>
									<Button.Group align="flex-start">
										<Button
											as="link"
											href={`https://www.youtube.com/watch?v=${video.videoId}`}
											target="_blank"
											rel="noreferrer nofollow noopener"
										>
											Watch on YouTube
										</Button>
									</Button.Group>
								</Card>
							))}
						</Card.Grid>
					</DocsSidebar.Content>
				</DocsSidebar.Grid>
			</Constrain>
			<NewSupport type="singleDoc" />
		</DocDataProvider>
	);
}
